// file: four0four-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('TeamController', ['$timeout', '$http', '$cookies', '$mdDialog', '$rootScope','ScoreCard',
            function($timeout, $http, $cookies, $mdDialog, $rootScope, ScoreCard) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                dbService($rootScope, $http, $timeout);
                // controller functions ========================================
                self.addTeamMember = function(ev) {
                    $mdDialog.show({
                        controller: AddTeamMemberController,
                        controllerAs: 'addTeamMemberCtrl',
                        templateUrl: '../views/add-team-member.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        $rootScope.reloadTeamMembers($rootScope, $http, $cookies, $timeout);				
                    }, function() {
                        // cancelled dialog						
                    });
                };

				// controller variables ========================================
				// Load all members of this given company
				var companyModuleId = $cookies.get('CompanyModuleID');

				$rootScope.teamController = this;
				$rootScope.teamController.companyUsers = new Array();
				
				ScoreCard.reloadCompanyUsers(companyModuleId, $rootScope, $http, $cookies, $rootScope.teamController);

                self.editTeam = function(selectedTeamMember, ev) {
                    $mdDialog.show({
                        controller: EditTeamMemberController,
                        controllerAs: 'editTeamMemberCtrl',
                        templateUrl: '../views/edit-team-member.tmpl.html',
                        locals: {
                            teamMember: selectedTeamMember
                        },
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        // close dialog						
                    }, function() {
                        // cancelled dialog						
                    });
                };

            }]);

})();
