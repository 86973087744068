// file: switch-issue-team-controller.js

function SwitchIssueTeamController (issue, $mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

	// CRITICAL: THIS NEEDS TO RETURN ALL TEAMS IN THE CURRENT COMPANY AS OPPOSED TO TEAMS YOU ARE A MEMBER OF
    // controller variables ====================================================
	var companyModuleId = $cookies.get('CompanyModuleID');
	var teamModuleId = $cookies.get('ModuleID');

	$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, function(companyTeams){
		self.companyTeams = companyTeams;
	});

    console.log(issue);
	
	self.issueToMove = issue;

    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveItem = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			// save new assigned team
			var newTeamModuleId = self.issue.assignedTeam
			
			if (teamModuleId != newTeamModuleId) {			
				$rootScope.dbMoveRow(teamModuleId, 'Issue', self.issueToMove.pkfieldval, newTeamModuleId, 'Issue', 
									function() {
										// TODO: Reload Issues
										$mdDialog.cancel();
									}, 
									function() {}, 
								$http);
			} else {
				$mdDialog.cancel();
			}
        }
    };
}
