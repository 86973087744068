// file: four0four-controller.js

(function() {

    'use strict';

    angular.module('traxion')
        .controller('TeamsController', ['$location', '$http', '$cookies', '$mdDialog', '$rootScope',
            function($location, $http, $cookies, $mdDialog, $rootScope) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;
				
				var companyModuleId = $cookies.get('CompanyModuleID');
				
				$rootScope.teamsController = self;
				
				$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, function(companyTeams) {
					self.companyTeams = companyTeams;
				});
				
                self.addTeam = function(ev) {
                    $mdDialog.show({
                        controller: AddTeamController,
                        controllerAs: 'addTeamCtrl',
                        templateUrl: '../views/add-team.tmpl.html',
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        //$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, this);
                    }, function() {
                        //$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, this);
                    });
                };

				self.loadTeam = function(team) {
					// ability to switch between teams
					$rootScope.loadTeam($cookies, $rootScope, $http, team.name);
					$mdDialog.cancel();			
					$location.path('/dashboard');
				};
				
                self.editTeam = function(selectedTeam, ev) {
                    $mdDialog.show({
                        controller: EditTeamController,
                        controllerAs: 'editTeamCtrl',
                        templateUrl: '../views/edit-team.tmpl.html',
                        locals: {
                            team: selectedTeam
                        },
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true
                    })
                    .then(function() {
                        //$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, this);
                    }, function() {
                        //$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, this);
                    });
                };

            }]);

})();
