// file: edit-team-controller.js

function EditTeamController (team, $mdDialog, $mdToast, $rootScope, $cookies, $http) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.team = team;

    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.saveTeam = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
			var companyModuleId = $cookies.get('CompanyModuleID');
			
			// Update the team name
			$rootScope.dbSaveFieldDirect(companyModuleId, 'Team',
    					'TeamID',
    					self.team.id,
    					'DisplayName',
    					self.team.displayName,
    					function() {
    						// Success
							$mdDialog.hide();
    					},
    					function() {
    						// Show error
    					}, $http);			
        }
    };

    self.deleteTeam = function(ev) {
        var confirm = $mdDialog.confirm()
            .title('Would you like to delete this team?')
            .content('This action is irreversible.')
            .ariaLabel('Delete')
            .targetEvent(ev)
            .ok('Yes')
            .cancel('No');

        $mdDialog.show(confirm).then(function() {
			var companyModuleId = $cookies.get('CompanyModuleID');
			
            $rootScope.dbDeleteDirect(companyModuleId, 'Team',
    						self.team.id,
    						function() {
    							$rootScope.reloadCompanyTeams(companyModuleId, $rootScope, $http, function(companyTeams) {
									$rootScope.teamsController.companyTeams = companyTeams;
								});
								$rootScope.reAuth();	// Reload teams for which this user has access.
    						},
    						function() {
    							// Show error
    						} , $http);
        }, function() {
            // TODO: need keep function here
            console.log('Keep');
        });
    };
}
