// file: authentication-controller.js

(function() {
    // purely for trying to fix git

	/*
	All companies for which you personally have access:
	$cookies.put('companies', angular.toJson(companies));

	All teams for which you personally have access:
	$cookies.put('teams', angular.toJson(teams));

	All members of the currently loaded team:
	$cookies.put('teamMembers', angular.toJson(teamMembers));
	// {company database}.UserTeam (filtered by current tean);

	All company users.  Local scope.
	self.companyUsers =

	All teams within the loaded company. Local scope.
	self.companyTeams =
	*/
    'use strict';

	var module = angular.module('traxion');

	module.controller('AuthenticationController', ['$cookies', '$mdToast', '$location', '$rootScope', '$http', '$timeout',
            function($cookies, $mdToast, $location, $rootScope, $http, $timeout) {

                // declarations ================================================
                var self = this;
                var root = $rootScope;

                // controller declarations =====================================
                self.selectedTab = 0;

                self.switchTab = function(tab) {
                    self.selectedTab = tab;

					if (tab == 1) {
						// initialize / prepare for new account creation / sign up.
						instanceService($rootScope, $http, $timeout);
					}
                };

				// initializes on click of tab header
				$timeout(function() {
					var element = angular.element('md-tab-item[aria-controls="tab-content-1"]');
					element.bind('click', function() {
						// initialize / prepare for new account creation / sign up.
						instanceService($rootScope, $http, $timeout);
					});
				}, 10);

                self.checkPasswordMatch = function() {
                    if (self.user.password === self.user.confirmPassword) {
                        self.match = true;
                    } else {
                        self.match = false;
                    }
                };

				// Inti the Auth and DB Services
				AuthService($rootScope, $http);
				dbService($rootScope, $http, $timeout);

				// Clear Login info
				$cookies.put('ModuleID', '');
				$rootScope.logoutUserDirect($cookies);
				
				$rootScope.deleteDatabase = function(companyOrTeamSysDatabaseId) {		
				
					///////////
					$rootScope.dbDeleteDirect('master', 'sysDatabase',
									companyOrTeamSysDatabaseId,
									function() {
										$location.path('/authentication');	// Force the user to login again.
									},
									function() {
										// Show error
									} , $http);		
					/////		
				}

				$rootScope.teamExists = function(team, companyTeams) {
					
					var exists = false;

					angular.forEach(companyTeams, function (row, key) {
						if (team.name == row.name) {
							exists = true;
						}
					});

					return exists;
									
				};
				
                self.login = function() {

                    // Authenticate User
                    $rootScope.authenticateUserDirect(
                        self.user.email,
                        self.user.password,
                        function(data){
							$cookies.put('email', self.user.email);
							$rootScope.authComplete(data, $cookies, $rootScope, $http, $location, $timeout);
							$location.path('/dashboard');
                        },
                        function(data) {
                            $mdToast.show(
                                $mdToast.simple()
                                    .content('Invalid email or password!')
                                    .position('top right')
                                    .hideDelay(3000)
                            );
                        },
						$http, $cookies
                    );


                };

				$rootScope.reAuth = function() {

					// Re-Authenticate the User
                    $rootScope.authenticateUserDirect(
                        $cookies.get('email'),
                        '',
                        function(data){
							$rootScope.authComplete(data, $cookies, $rootScope, $http, $location, $timeout);
                        },
                        function(data) {
                            // Do nothing
                        },
						$http, $cookies
                    );
				};

                self.showBilling = function(form) {
                    if (form.$pristine) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('Fill out form.')
                                .position('top right')
                                .hideDelay(3000)
                        );
                    } else if (form.$invalid) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('Fix errors in form.')
                                .position('top right')
                                .hideDelay(3000)
                        );
                        form.companyName.$touched = true;
                        form.confirmPassword.$touched = true;
                        form.defaultTeam.$touched = true;
                        form.email.$touched = true;
                        form.firstName.$touched = true;
                        form.lastName.$touched = true;
                        form.password.$touched = true;
                    } else if (!self.match) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('Make sure password and confirm password match.')
                                .position('top right')
                                .hideDelay(3000)
                        );
                    } else {
                        self.signUpSubmit = true;
                    }
                };

                self.setCardType = function() {
                    // TODO: set self.card.type to either (AmEx, MasterCard, Visa)
                    //       based on Stripe response after verifying card
                };

				self.signUp = function(form) {
                    if (form.$pristine) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('Fill out form.')
                                .position('top right')
                                .hideDelay(3000)
                        );
                    } else if (form.$invalid) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('Fix errors in form.')
                                .position('top right')
                                .hideDelay(3000)
                        );
                        form.address1.$touched = true;
                        form.address2.$touched = true;
                        form.city.$touched = true;
                        form.state.$touched = true;
                        form.postalCode.$touched = true;
                        form.cardNumber.$touched = true;
                        form.cardName.$touched = true;
                        form.expirationMonth.$touched = true;
                        form.expirationYear.$touched = true;
                        form.securityCode.$touched = true;
                    } else {
                        // TODO: Need to implement billing form

                        // Important: instanceService($rootScope, $http, $timeout); should be called on tab load.
    					var newAccountData = $rootScope.newAccountDetails();

    					$rootScope.dbWriteFieldValue('AccountType', newAccountData.FieldGroupList, "Company");
    					$rootScope.dbWriteFieldValue("AccountCode", newAccountData.FieldGroupList, self.user.companyName);
    					$rootScope.dbWriteFieldValue('AccountType2', newAccountData.FieldGroupList, "Team");
    					$rootScope.dbWriteFieldValue("AccountCode2", newAccountData.FieldGroupList, self.user.teamName);
    					$rootScope.dbWriteFieldValue("FirstName", newAccountData.FieldGroupList, self.user.firstName);
    					$rootScope.dbWriteFieldValue("LastName", newAccountData.FieldGroupList, self.user.lastName);
    					$rootScope.dbWriteFieldValue("Email", newAccountData.FieldGroupList, self.user.email);
    					$rootScope.dbWriteFieldValue("Password", newAccountData.FieldGroupList, self.user.password);

    					// Create the new account.
    					$rootScope.dbSaveInternal(function(newAccountData) {
    													processNewAccount(newAccountData, self.user.email, self.user.password, newAccountData.ModuleID, $rootScope, $http, $cookies, self.user.teamName, $location, $timeout);
    												},
    												function(data, status, url){
    												}, $http);
                    }
				};

				$rootScope.sendInvite = function(companyModuleId, userId) {

					$rootScope.dbReadDirect(companyModuleId, 'User', userId,
						function(entityData) {
							$rootScope.dbExecuteDirectCustomDataPipeline(companyModuleId, 'User', 'SendInvite',
										entityData,
										function() {
											// On Success
										},
										function() {
											// On Failure
										},
										$http);
						},
						function () {
						}, $http);
				};

				// Reload Members of the Current Team
				$rootScope.reloadTeamMembers = function($rootScope, $http, $cookies, $timeout) {
					reloadTeamMembers($rootScope, $http, $cookies, $timeout);
				};

				// Reload all users within the current company
				$rootScope.reloadCompanyUsers = function(companyModuleId, $rootScope, $http, $cookies, controller) {
					reloadCompanyUsers(companyModuleId, $rootScope, $http, $cookies, controller);
				};

				// Load companyTeams
				$rootScope.reloadCompanyTeams = function(companyModuleId, $rootScope, $http, callback) {
					$rootScope.dbSearchDirect(companyModuleId, 'Team', 100, 1,
						function(data) {
							var companyTeams = new Array();

							angular.forEach(data.RowList, function (row, key) {
								var teamId = $rootScope.dbReadRowFieldValue('TeamID', row, data.FieldSchemaList);
								var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.FieldSchemaList);
								var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.FieldSchemaList);

								var team = angular.fromJson('{"id":"","name":""}');

								//team.id = moduleName;
								team.id = teamId;
								team.name = moduleName;
								team.displayName = displayName;

								if ($rootScope.teamExists(team, companyTeams) == false) {
									companyTeams.push(team);
								}								
							});
                                        
							callback(companyTeams);
						},
						function() {
							// show error
						},
						$http);
				};

				$rootScope.loadTeam = function($cookies, $rootScope, $http, moduleId) {
						loadTeam($cookies, $rootScope, $http, moduleId);
						setLastAccessedDateTime(moduleId, $rootScope, $http);
					};

				$rootScope.loadCompany = function($cookies, $rootScope, $http, companyModuleId) {

						var data = $rootScope.authResponse;

						// Find the companySysDatabaseID
						var companySysDatabaseID = null;

						angular.forEach(data.ModuleList.RowList, function (row, key) {
							var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
							var moduleType = $rootScope.dbReadRowFieldValue('Type', row, data.ModuleList.FieldSchemaList);
							var sysDatabaseID = $rootScope.dbReadRowFieldValue('sysDatabaseID', row, data.ModuleList.FieldSchemaList);

							if ((moduleType == 'Company') && (moduleName == companyModuleId)) {
								companySysDatabaseID = sysDatabaseID;
							}
						});

						// Find the first team in the given company. Note: In the future we will load the default team.
						var teamModuleId = null;

						angular.forEach(data.ModuleList.RowList, function (row, key) {
							var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
							var moduleType = $rootScope.dbReadRowFieldValue('Type', row, data.ModuleList.FieldSchemaList);
							var parentSysDatabaseID = $rootScope.dbReadRowFieldValue('ParentSysDatabaseID', row, data.ModuleList.FieldSchemaList);

							if ((moduleType == 'Team') && (parentSysDatabaseID == companySysDatabaseID)) {
								teamModuleId = moduleName;
							}
						});

						loadTeam($cookies, $rootScope, $http, teamModuleId);

						setLastAccessedDateTime(companyModuleId, $rootScope, $http);
						setLastAccessedDateTime(teamModuleId, $rootScope, $http);
					};

				$rootScope.authComplete = function(data, $cookies, $rootScope, $http, $location, $timeout) {

						$rootScope.authResponse = data;

						var moduleId =  $rootScope.authResponse.DefaultModuleID;

						$rootScope.loadTeam($cookies, $rootScope, $http, moduleId);

						$rootScope.reloadTeamMembers($rootScope, $http, $cookies, $timeout);
					};

				$rootScope.addCurrentUserToCompanyAndTeam = function(companyModuleId, teamModuleId, teamName, $cookies, $rootScope, $http, $location, $timeout, successCallback) {

					/////////////////////////
					// Add To Company User table
					$rootScope.dbNewDirect(companyModuleId, 'User',
						function(data) {
							// Set basic user values
							$rootScope.dbWriteFieldValue('sysLoginID', data.FieldGroupList, '{sysLoginID}');
							// Write the new row to the database.
							$rootScope.dbSaveDirect(companyModuleId, 'User', data,
							function(newUserData) {
								var userId = newUserData.PrimaryKey;

								$rootScope.dbNewDirect(companyModuleId, 'Team',
									function(newTeamData) {
										// Set basic user values
										$rootScope.dbWriteFieldValue('DisplayName', newTeamData.FieldGroupList, teamName);
										$rootScope.dbWriteFieldValue('Name', newTeamData.FieldGroupList, teamModuleId);
										// Write the new row to the database.
										$rootScope.dbSaveDirect(companyModuleId, 'Team', newTeamData,
											function(newTeamResult) {
												// Create UserTeam entry
												//////////////////////////////
												$rootScope.dbNewDirect(companyModuleId, 'UserTeam',
													function(newUserTeamData) {
														// Set basic user values
														$rootScope.dbWriteFieldValue('UserID', newUserTeamData.FieldGroupList, userId);
														$rootScope.dbWriteFieldValue('TeamID', newUserTeamData.FieldGroupList, newTeamResult.PrimaryKey);
														// Write the new UserTeam row to the database.
														$rootScope.dbSaveDirect(companyModuleId, 'UserTeam', newUserTeamData,
															function(newUserTeamResult) {
																successCallback($cookies, $rootScope, $http, $location, $timeout);
															},
															function() {
																// Do nothing on fail.
															}, $http);

													},
													function() {

													}, $http);
												//////////////////////////////
											},
											function() {
												// Do nothing on fail.
											}, $http);
									},
									function() {

									}, $http);

							},
							function() {
								// Do nothing on fail.
								}, $http);
						},
						function(data, status, url){
						}, $http);
					/////////////////////////
				};
				
				self.years = [];
				
				var currentDate = new Date();
				
				for (var year = currentDate.getFullYear(); year < currentDate.getFullYear() + 20; year++) {
					self.years.push(year);
				}
				
				// MONTHS List
				self.months  = [
								{
									"name": "01 - January",
									"value": "01"
								},
								{
									"name": "02 - February",
									"value": "02"
								},
								{
									"name": "03 - March",
									"value": "03"
								},
								{
									"name": "04 - April",
									"value": "04"
								},
								{
									"name": "05 - May",
									"value": "05"
								},
								{
									"name": "06 - June",
									"value": "06"
								},
								{
									"name": "07 - July",
									"value": "07"
								},
								{
									"name": "08 - August",
									"value": "08"
								},
								{
									"name": "09 - September",
									"value": "09"
								},
								{
									"name": "10 - October",
									"value": "10"
								},
								{
									"name": "11 - November",
									"value": "11"
								},
								{
									"name": "12 - December",
									"value": "12"
								}								
							 ];
				
				// STATE LIST
				self.states = [
								{
									"name": "Alabama",
									"abbreviation": "AL"
								},
								{
									"name": "Alaska",
									"abbreviation": "AK"
								},
								{
									"name": "American Samoa",
									"abbreviation": "AS"
								},
								{
									"name": "Arizona",
									"abbreviation": "AZ"
								},
								{
									"name": "Arkansas",
									"abbreviation": "AR"
								},
								{
									"name": "California",
									"abbreviation": "CA"
								},
								{
									"name": "Colorado",
									"abbreviation": "CO"
								},
								{
									"name": "Connecticut",
									"abbreviation": "CT"
								},
								{
									"name": "Delaware",
									"abbreviation": "DE"
								},
								{
									"name": "District Of Columbia",
									"abbreviation": "DC"
								},
								{
									"name": "Federated States Of Micronesia",
									"abbreviation": "FM"
								},
								{
									"name": "Florida",
									"abbreviation": "FL"
								},
								{
									"name": "Georgia",
									"abbreviation": "GA"
								},
								{
									"name": "Guam",
									"abbreviation": "GU"
								},
								{
									"name": "Hawaii",
									"abbreviation": "HI"
								},
								{
									"name": "Idaho",
									"abbreviation": "ID"
								},
								{
									"name": "Illinois",
									"abbreviation": "IL"
								},
								{
									"name": "Indiana",
									"abbreviation": "IN"
								},
								{
									"name": "Iowa",
									"abbreviation": "IA"
								},
								{
									"name": "Kansas",
									"abbreviation": "KS"
								},
								{
									"name": "Kentucky",
									"abbreviation": "KY"
								},
								{
									"name": "Louisiana",
									"abbreviation": "LA"
								},
								{
									"name": "Maine",
									"abbreviation": "ME"
								},
								{
									"name": "Marshall Islands",
									"abbreviation": "MH"
								},
								{
									"name": "Maryland",
									"abbreviation": "MD"
								},
								{
									"name": "Massachusetts",
									"abbreviation": "MA"
								},
								{
									"name": "Michigan",
									"abbreviation": "MI"
								},
								{
									"name": "Minnesota",
									"abbreviation": "MN"
								},
								{
									"name": "Mississippi",
									"abbreviation": "MS"
								},
								{
									"name": "Missouri",
									"abbreviation": "MO"
								},
								{
									"name": "Montana",
									"abbreviation": "MT"
								},
								{
									"name": "Nebraska",
									"abbreviation": "NE"
								},
								{
									"name": "Nevada",
									"abbreviation": "NV"
								},
								{
									"name": "New Hampshire",
									"abbreviation": "NH"
								},
								{
									"name": "New Jersey",
									"abbreviation": "NJ"
								},
								{
									"name": "New Mexico",
									"abbreviation": "NM"
								},
								{
									"name": "New York",
									"abbreviation": "NY"
								},
								{
									"name": "North Carolina",
									"abbreviation": "NC"
								},
								{
									"name": "North Dakota",
									"abbreviation": "ND"
								},
								{
									"name": "Northern Mariana Islands",
									"abbreviation": "MP"
								},
								{
									"name": "Ohio",
									"abbreviation": "OH"
								},
								{
									"name": "Oklahoma",
									"abbreviation": "OK"
								},
								{
									"name": "Oregon",
									"abbreviation": "OR"
								},
								{
									"name": "Palau",
									"abbreviation": "PW"
								},
								{
									"name": "Pennsylvania",
									"abbreviation": "PA"
								},
								{
									"name": "Puerto Rico",
									"abbreviation": "PR"
								},
								{
									"name": "Rhode Island",
									"abbreviation": "RI"
								},
								{
									"name": "South Carolina",
									"abbreviation": "SC"
								},
								{
									"name": "South Dakota",
									"abbreviation": "SD"
								},
								{
									"name": "Tennessee",
									"abbreviation": "TN"
								},
								{
									"name": "Texas",
									"abbreviation": "TX"
								},
								{
									"name": "Utah",
									"abbreviation": "UT"
								},
								{
									"name": "Vermont",
									"abbreviation": "VT"
								},
								{
									"name": "Virgin Islands",
									"abbreviation": "VI"
								},
								{
									"name": "Virginia",
									"abbreviation": "VA"
								},
								{
									"name": "Washington",
									"abbreviation": "WA"
								},
								{
									"name": "West Virginia",
									"abbreviation": "WV"
								},
								{
									"name": "Wisconsin",
									"abbreviation": "WI"
								},
								{
									"name": "Wyoming",
									"abbreviation": "WY"
								}
							];
				
            }]);
})();

function processNewAccount(newAccountData, email, password, teamModuleId, $rootScope, $http, $cookies, teamName, $location, $timeout) {

	// Authenticate User
	$rootScope.authenticateUserDirect(email, password,
			function(authUserData){

				var companyModuleId  = '';

				angular.forEach(authUserData.ModuleList.RowList, function (row, key) {
					var moduleName = $rootScope.dbReadRowFieldValue('Name', row, authUserData.ModuleList.FieldSchemaList);
					var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, authUserData.ModuleList.FieldSchemaList);
					var moduleType = $rootScope.dbReadRowFieldValue('Type', row, authUserData.ModuleList.FieldSchemaList);
					var parentSysDatabaseID = $rootScope.dbReadRowFieldValue('ParentSysDatabaseID', row, authUserData.ModuleList.FieldSchemaList);

					if (moduleType == 'Company') {
						companyModuleId = moduleName;
					}
				});

				// Add User to Company and Team
				$rootScope.addCurrentUserToCompanyAndTeam(companyModuleId, teamModuleId, teamName, $cookies, $rootScope, $http, $location, $timeout, function() {
					// Display the newly created company and team.
					$rootScope.authComplete(authUserData, $cookies, $rootScope, $http, $location, $timeout);
					$location.path('/dashboard');
				});
			},
            function(data) {
			},
			$http, $cookies);
}

function loadTeam($cookies, $rootScope, $http, moduleId) {

	var data = $rootScope.authResponse;

	// The ModuleID equates the the database for a given team or company
	$cookies.put('ModuleID', moduleId);
	$cookies.put('currentUser', data.FirstName + ' ' + data.LastName);

	// Add Companies
	var companies = new Array();

	// List all companies for which the user has rights
	// Also find the sysDatabaseID for the current module.
	var companySysDatabaseID = null;

	angular.forEach(data.ModuleList.RowList, function (row, key) {
		var sysDatabaseID = $rootScope.dbReadRowFieldValue('sysDatabaseID', row, data.ModuleList.FieldSchemaList);
		var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
		var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.ModuleList.FieldSchemaList);
		var moduleType = $rootScope.dbReadRowFieldValue('Type', row, data.ModuleList.FieldSchemaList);
		var parentSysDatabaseID = $rootScope.dbReadRowFieldValue('ParentSysDatabaseID', row, data.ModuleList.FieldSchemaList);

		if (moduleType == 'Company') {
			var company = angular.fromJson('{"id":"","name":"","pk":""}');

			company.id = moduleName;
			company.name = displayName;
			company.pk = sysDatabaseID;

			companies.push(company);
		} else if ((moduleType == 'Team') && (moduleName == moduleId)) {
			companySysDatabaseID = parentSysDatabaseID;
		}
	});

	$cookies.put('companies', angular.toJson(companies));

	// Locate the ModuleID for the current company
	angular.forEach(data.ModuleList.RowList, function (row, key) {
		var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
		var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.ModuleList.FieldSchemaList);
		var moduleType = $rootScope.dbReadRowFieldValue('Type', row, data.ModuleList.FieldSchemaList);
		var sysDatabaseID = $rootScope.dbReadRowFieldValue('sysDatabaseID', row, data.ModuleList.FieldSchemaList);

		if ((moduleType == 'Company') && (companySysDatabaseID == sysDatabaseID)) {
			$cookies.put('CompanyModuleID', moduleName);
                        $cookies.put('CompanyName', displayName);
			$cookies.put('CompanysysDatabaseID', companySysDatabaseID);
			setLastAccessedDateTime(moduleName, $rootScope, $http);
		}
	});

	// Add Teams
	var teams = new Array();

	// Add Current Teams
	angular.forEach(data.ModuleList.RowList, function (row, key) {
		var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
		var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.ModuleList.FieldSchemaList);

		if (moduleName == moduleId) {
			var team = angular.fromJson('{"id":"","name":""}');

			team.id = moduleName;
			team.name = displayName;

			$cookies.put('TeamName', displayName);

			teams.push(team);
		}
	});

	// Add all others teams within the current company.
	// Teams are always sorted by most recently accessed
	angular.forEach(data.ModuleList.RowList, function (row, key) {
		var moduleName = $rootScope.dbReadRowFieldValue('Name', row, data.ModuleList.FieldSchemaList);
		var displayName = $rootScope.dbReadRowFieldValue('DisplayName', row, data.ModuleList.FieldSchemaList);
		var moduleType = $rootScope.dbReadRowFieldValue('Type', row, data.ModuleList.FieldSchemaList);
		var parentSysDatabaseID = $rootScope.dbReadRowFieldValue('ParentSysDatabaseID', row, data.ModuleList.FieldSchemaList);

		if ((moduleType == 'Team') && (parentSysDatabaseID == companySysDatabaseID) && (moduleName != moduleId)) {
			var team = angular.fromJson('{"id":"","name":""}');

			team.id = moduleName;
			team.name = displayName;

			if (moduleName == moduleId) {
				$cookies.put('TeamName', displayName);
			}

			teams.push(team);
		}
	});

	$cookies.put('teams', angular.toJson(teams));
}

function teamMemberExists(teamMemberName, teamMembers) {

	var exists = false;

	angular.forEach(teamMembers, function (row, key) {
		if (row.name == teamMemberName) {
			exists = true;
		}
	});

	return exists;
}

function setLastAccessedDateTime(moduleId,
									$rootScope,
									$http) {

	$rootScope.dbSearchDirectCustomDataPipeline('master', moduleId, 'SetLastAccessedDateTime',
												function(data) {
													// do nothing on success.
												},
												function() {
													// show error
												},
												$http);

}

// Reloads the list of users that are assigned to the given company.
function reloadCompanyUsers(companyModuleId, $rootScope, $http, $cookies, controller) {

	$rootScope.dbSearchDirect(companyModuleId, 'User', 100, 1,
		function(companyUserData) {

			var companyUsers = new Array();

			angular.forEach(companyUserData.RowList, function (row, key) {
				var userDisplayNamee = $rootScope.dbReadRowFieldValue('sysLoginID', row, companyUserData.FieldSchemaList); // The sysLoginID is translated to a user display name on the server via UserPicker input type.
				var userId = $rootScope.dbReadRowFieldValue('UserID', row, companyUserData.FieldSchemaList);

				if (teamMemberExists(userDisplayNamee, companyUsers) == false) {
					var user = angular.fromJson('{"name":"", "id":""}');

					user.name = userDisplayNamee;
					user.id = userId;

					companyUsers.push(user);
				}
			});

			if (controller.companyUsers != null) {
				controller.companyUsers = companyUsers;
			}

		}, function() {
			// show error
		}, $http);
}

// Reloads the list of users that are assigned to the current team.
function reloadTeamMembers($rootScope, $http, $cookies, $timeout) {

	dbService($rootScope, $http, $timeout);

	var teamModuleId = $cookies.get('ModuleID');
	var companyModuleId = $cookies.get('CompanyModuleID');

	// Clear team members
	$cookies.put('teamMembers', angular.toJson(new Array()));

	// Search Team by Team Name within the current company
	$rootScope.dbSearchDirectFiltered(companyModuleId, 'Team', 'Name', teamModuleId, 100, 1,
		function(companyTeamData) {
			angular.forEach(companyTeamData.RowList, function (teamData, key) {
				var teamId = $rootScope.dbReadRowFieldValue('TeamID', teamData, companyTeamData.FieldSchemaList);

				////////////////////////////////////
				$rootScope.dbSearchDirectFiltered(companyModuleId, 'UserTeam', 'TeamID', teamId, 100, 1,
					function(userTeamData) {



						angular.forEach(userTeamData.RowList, function (userData, key) {
							var userId = $rootScope.dbReadRowFieldValue('UserID', userData, userTeamData.FieldSchemaList);

							///////////////////////////
							// Load Single Team Member
							$rootScope.dbSearchDirectFiltered(companyModuleId, 'User', 'UserID', userId, 100, 1,
								function(simpleUserData) {
									// On Success
									angular.forEach(simpleUserData.RowList, function (userData, key) {
										var teamMember = angular.fromJson('{"name":"", "id":""}');

										teamMember.name = $rootScope.dbReadRowFieldValue('sysLoginID', userData, simpleUserData.FieldSchemaList);
										teamMember.id = userId;

										var teamMembers = angular.fromJson($cookies.get('teamMembers'));

										teamMembers.push(teamMember);

										$cookies.put('teamMembers', angular.toJson(teamMembers));

										if ($rootScope.teamController != null) {
											$rootScope.teamController.teamMembers = teamMembers;
										}
									});
								},
								function () {
								}, $http);
							///////////////////////////
						});
					}, function() {
						// show error
					}, $http);

				///////////////////////////////////

			});
		}, function() {
			// show error
		}, $http);
}
