// file: edit-account-controller.js

function EditAccountController ($cookies, $mdDialog, $mdToast, $rootScope, $http, $scope, $timeout) {
    // declarations ============================================================
    var self = this;
    var root = $rootScope;

    // controller variables ====================================================
    self.companies = angular.fromJson($cookies.get('companies'));
    self.companyTeams = angular.fromJson($cookies.get('teams'));

    self.user = {};
    
    self.readUserSettings = function() {
            // Read the account information.
            $rootScope.dbReadDirect('master', 'sysLogin', '{sysLoginID}',
            function(userSettingsData) {						
                    self.user.firstName = $rootScope.dbReadFieldValue('FirstName', userSettingsData.FieldGroupList);
                    self.user.lastName = $rootScope.dbReadFieldValue('LastName', userSettingsData.FieldGroupList);
                    self.user.email = $rootScope.dbReadFieldValue('Username', userSettingsData.FieldGroupList);                    
                    self.user.password = '';           
					self.user.confirmPassword = '';
					self.loadDefaultTeam();
            },
            function () {
            }, $http);
    };
    
	self.loadDefaultTeam = function() {
		
		self.user.defaultCompany = $cookies.get('CompanyModuleID');
		self.getTeamsForCompany(self.user.defaultCompany);
		
		self.team = [];		
        self.team.defaultTeam = $cookies.get('ModuleID');
		self.team.defaultTeamName = $cookies.get('TeamName');
		
	};
	
    self.readUserSettings();
    // controller functions ====================================================
    self.cancel = function() {
        $mdDialog.cancel();
    };

    self.getTeamsForCompany = function(companyId){
      $rootScope.reloadCompanyTeams(companyId, $rootScope, $http, function(companyTeams){
		  self.companyTeams = companyTeams;
		  self.user.defaultTeam = companyTeams[0].id;
	  });
    }

	self.readCurrentSettings = function(callback) {
		// Read the account information.
		$rootScope.dbReadDirect('master', 'sysLogin', '{sysLoginID}',
			function(userSettingsData) {
				callback(userSettingsData);
			},
			function () {
			}, $http);
	};
	
    self.saveFirstName = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
          $rootScope.dbSaveFieldDirect('master', 'sysLogin', 'sysLoginID', '{sysLoginID}', 'FirstName', self.user.firstName,
                 function() {
                   $mdDialog.hide();
                 },
                 function() {
                  // Show error
                 }
                 , $http);
        }
    };

    self.saveLastName = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
          $rootScope.dbSaveFieldDirect('master', 'sysLogin', 'sysLoginID', '{sysLoginID}', 'LastName', self.user.lastName,
                 function() {
                  $mdDialog.hide();
                 },
                 function() {
                  // Show error
                 }
                 , $http);
        }
    };


    self.saveEmail = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
              $rootScope.dbSaveFieldDirect('master', 'sysLogin', 'sysLoginID', '{sysLoginID}', 'Username', self.user.email,
                     function() {
					  $rootScope.reAuth();
                      $mdDialog.hide();
                     },
                     function() {
                      // Show error
                     }
                     , $http);      
        }
    };

    self.saveDefaultTeam = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {
              $rootScope.dbSaveFieldDirect('master', 'sysLogin', 'sysLoginID', '{sysLoginID}', 'DefaultDatabaseGuid', self.user.defaultTeam,
                     function() {
                      $mdDialog.hide();
					  $rootScope.reAuth();
					  $timeout(function() { self.readUserSettings(); }, 2000);
                     },
                     function() {
                      // Show error
                     }
                     , $http);
        }
    };

	self.checkPasswordMatch = function() {
                    if (self.user.password === self.user.confirmPassword) {
                        self.match = true;
                    } else {
                        self.match = false;
                    }
                };
				
    self.savePassword = function(form) {
        if (form.$pristine) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fill out all form fields.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else if (form.$invalid) {
            $mdToast.show(
                $mdToast.simple()
                    .content('Fix errors in form.')
                    .position('top right')
                    .hideDelay(3000)
            );
        } else {			
			$rootScope.dbSaveFieldDirect('master', 'sysLogin', 'sysLoginID', '{sysLoginID}', 'PwdHash', self.user.password,
                     function() {
                      $mdDialog.hide();
                     },
                     function() {
                      // Show error
                     }
                     , $http); 
        }
    };
}
