(function() {
    'use strict';

    angular.module('3d')
     .directive('d3Bars', function() {
          return {
            restrict: 'EA',
            scope: {
              average:'=',
              goal: '=',
              data: "=",
              label: "@",
              onClick: "&"
            },
            link: function(scope, iElement, iAttrs) {
              var margin ={top:20, right:30, bottom:30, left:40},
                  width=560-margin.left - margin.right, 
                  height=300-margin.top-margin.bottom;

              // scale to ordinal because x axis is not numerical
              var x = d3.scale.ordinal().rangeRoundBands([0, width], .1);

              //scale to numerical value by height
              var y = d3.scale.linear().range([height, 0]);


              var chart = d3.select(iElement[0])  
                            .append("svg")  //append svg element inside #chart
                            .attr("width", width+(2*margin.left)+margin.right)    //set width
                            .attr("height", height+margin.top+margin.bottom);  //set height
              var xAxis = d3.svg.axis()
                            .scale(x)
                            .orient("bottom");  //orient bottom because x-axis will appear below the bars

              var yAxis = d3.svg.axis()
                            .scale(y)
                            .orient("left");

                var draw_line = d3.svg.line()
                .x(function(d,i) { return transfer_x(i); })
                .y(function(d) { return transfer_y(d); })
                .interpolate("cardinal");

                chart.append("svg:g")
                .attr("transform", "translate(" + margin.left + "," + margin.top + ")")

                x.domain(scope.data.map(function(d){ return d.letter}));
                y.domain([0, d3.max(scope.data, function(d){return d.frequency})]);
                var bar = chart.selectAll("g")
                                  .data(scope.data)
                                .enter()
                                  .append("g")
                                  .attr("transform", function(d, i){
                                    return "translate("+x(d.letter)+", 0)";
                                  });
                bar.append("rect")
                    .attr("y", function(d) { 
                      return y(d.frequency); 
                    })
                    .attr("x", function(d,i){
                      return x.rangeBand()+(margin.left/4);
                    })
                    .attr("height", function(d) { 
                      return height - y(d.frequency); 
                    })
                    .attr("width", x.rangeBand());  //set width base on range on ordinal data

                bar.append("text")
                    .attr("x", x.rangeBand()+margin.left )
                    .attr("y", function(d) { return y(d.frequency) -10; })
                    .attr("dy", ".75em")
                    .text(function(d) { return d.frequency; });
                chart.append("g")
                      .attr("class", "x axis")
                      .attr("transform", "translate("+margin.left+","+ height+")")        
                      .call(xAxis);
                chart.append("g")
                      .attr("class", "y axis")
                      .attr("transform", "translate("+margin.left+",0)")
                      .call(yAxis)
                      .append("text")
                      .attr("transform", "rotate(-90)")
                      .attr("y", 6)
                      .attr("dy", ".71em")
                      .style("text-anchor", "end")
                      .text("Sales");

                chart.append("line")          // attach a line
                  .style("stroke", "red")  // colour the line
                  .attr("x1", 50)     // x position of the first end of the line
                  .attr("y1", y(scope.goal))  // y position of the first end of the line
                  .attr("x2", width)     // x position of the second end of the line
                  .attr("y2", y(scope.goal));

                  chart.append("line")          // attach a line
                    .style("stroke", "green")  // colour the line
                    .attr("x1", 50)     // x position of the first end of the line
                    .attr("y1", y(scope.average))  // y position of the first end of the line
                    .attr("x2", width)     // x position of the second end of the line
                    .attr("y2", y(scope.average));


              function type(d) {
                  d.letter = +d.letter; // coerce to number
                  return d;
                }
            }
          };
        });

})();
