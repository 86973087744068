(function() {
    'use strict';

    angular.module('traxion')
      .factory('ScoreCard',['$rootScope', '$log', '$http',function($rootScope, $log, $http){

        var _userProfile = {accessToken: ''};
        var weeklyGrid = [];
        var monthlyGrid = [];

          // Reloads the list of users that are assigned to the given company.
          var _reloadCompanyUsers = function(companyModuleId, $rootScope, $http, $cookies, controller) {

                  $rootScope.dbSearchDirect(companyModuleId, 'User', 100, 1,
                          function(companyUserData) {

                                  var companyUsers = new Array();

                                  angular.forEach(companyUserData.RowList, function (row, key) {
                                          var userDisplayNamee = $rootScope.dbReadRowFieldValue('sysLoginID', row, companyUserData.FieldSchemaList); // The sysLoginID is translated to a user display name on the server via UserPicker input type.
                                          var userId = $rootScope.dbReadRowFieldValue('UserID', row, companyUserData.FieldSchemaList);

                                          if (teamMemberExists(userDisplayNamee, companyUsers) == false) {
                                                  var user = angular.fromJson('{"name":"", "id":""}');

                                                  user.name = userDisplayNamee;
                                                  user.id = userId;

                                                  companyUsers.push(user);
                                          }
                                  });

                                  if (controller.companyUsers != null) {
                                          controller.companyUsers = companyUsers;
                                  }

                          }, function() {
                                  // show error
                          }, $http);
          }
          var replaceIntervalWithAverage = function($rootScope, data) {

                  // Change the column name from Interval to Average
                  angular.forEach(data.FieldSchemaList, function (fieldSchema, key) {
                          if (fieldSchema.DisplayName == 'Interval') {
                                  fieldSchema.DisplayName = 'Average';
                          }
                  });

                  // Calculate the average.
                  angular.forEach(data.RowList, function (row, key) {
                          // Loop through each field.
                          var total = 0;
                          var intervals = 0;
                          var reading = false;
                          var type = '';

                          angular.forEach(data.FieldSchemaList, function (fieldSchema, key) {
                                  if (reading) {
                                          var cellValue = $rootScope.dbReadRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList);

                                          cellValue = cellValue + "";
                                          cellValue = cellValue.replace('$', '').replace('%', '');

                                          if (isNumeric(cellValue)) {
                                                  total = total + parseFloat(cellValue);
                                                  intervals++;

                                                  // Format for type
                                                  switch (type)
                                                  {
                                                          case "Currency":
                                                                  $rootScope.dbWriteRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList, "$" + cellValue);
                                                                  break;

                                                          case "Percent":
                                                                  $rootScope.dbWriteRowFieldValue(fieldSchema.ID, row, data.FieldSchemaList, cellValue + "%");
                                                                  break;
                                                  }
                                          }
                                  } else if (fieldSchema.ID == 'Type') {
                                          reading = true;
                                          type = $rootScope.dbReadRowFieldValue("Type", row, data.FieldSchemaList);
                                          fieldSchema.Hidden = true;
                                  }
                          });

                          if ((total > 0) && (intervals > 0)) {
                                  var average = total / intervals;
                                  $rootScope.dbWriteRowFieldValue('Interval', row, data.FieldSchemaList, average);
                          } else {
                                  $rootScope.dbWriteRowFieldValue('Interval', row, data.FieldSchemaList, '');
                          }
                  });

                  return data;
          };

        return {
          reloadCompanyUsers : function(companyModuleId, $rootScope, $http, $cookies, controller) {
                  _reloadCompanyUsers(companyModuleId, $rootScope, $http, $cookies, controller);
          },
          reloadScorecardFromDb: function(self, $rootScope, $http, $log, $cookies, interval, callback) {

                  $rootScope.dbSearchDirectCustomDataPipeline($cookies.get("ModuleID"), 'Scorecard', 'ScorecardPivotTable' + interval,
                                                                                                          function(data) {
                                                                                                            switch (interval)
                                                                                                            {
                                                                                                                    case 'Weekly':
                                                                                                                            weeklyGrid = replaceIntervalWithAverage($rootScope, data);
                                                                                                                            break;

                                                                                                                    default:
                                                                                                                            monthlyGrid = replaceIntervalWithAverage($rootScope, data);
                                                                                                                            break;
                                                                                                            }
                                                                                                            callback();
                                                                                                          },
                                                                                                          function() {
                                                                                                                  // show error
                                                                                                          },
                                                                                                          $http);
          },
          getWeeklyGrid:function(){
            return weeklyGrid;
          },
          getMonthlyGrid:function(){
            return monthlyGrid;
          },
          getData: function(){
            return data;
          },
        };
      }]);

})();
